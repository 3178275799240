var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container-fluid d-flex align-items-stretch h-100 flex-fill"
    },
    [
      _c(
        "aside",
        { staticClass: "d-flex flex-column" },
        [
          _c("TeamHeader", {
            attrs: { team_name: _vm.info.name, image: _vm.info.logo_url }
          }),
          _c("MapNavigation", {
            attrs: { "available-map-ids": _vm.teamMapIds },
            on: {
              select_map: function($event) {
                return _vm.select_map($event)
              }
            }
          })
        ],
        1
      ),
      _c(
        "main",
        { staticClass: "flex-fill mt-4 mb-4" },
        [
          _c(
            "div",
            {
              staticClass:
                "team-headline d-flex justify-content-between align-items-end mb-4"
            },
            [
              _c("div", {}, [
                _c("h1", [
                  _vm._v(" " + _vm._s(_vm.info.name) + " "),
                  _vm.info.region
                    ? _c("small", [_vm._v(_vm._s(_vm.info.region))])
                    : _vm._e()
                ]),
                _c(
                  "div",
                  { staticClass: "d-flex align-items-baseline" },
                  [
                    _c("h6", { staticClass: "mr-1 text-muted" }, [
                      _vm._v("Members:")
                    ]),
                    !_vm.hideBrokenStuff
                      ? _vm._l(_vm.sortedMembers, function(member) {
                          return _c(
                            "router-link",
                            {
                              key: member.id,
                              staticClass: "ml-2",
                              class: !member.is_active ? "member-inactive" : "",
                              attrs: {
                                to:
                                  "/player/" +
                                  member.id +
                                  "/stats/" +
                                  _vm.$route.params.map_id
                              }
                            },
                            [_vm._v(" " + _vm._s(member.name) + " ")]
                          )
                        })
                      : _vm._l(_vm.sortedMembers, function(member) {
                          return _c(
                            "div",
                            {
                              key: member.id,
                              staticClass: "ml-2",
                              class: !member.is_active ? "member-inactive" : ""
                            },
                            [_vm._v(" " + _vm._s(member.name) + " ")]
                          )
                        })
                  ],
                  2
                )
              ]),
              _vm.mapId
                ? _c(
                    "b-nav",
                    { attrs: { pills: "", align: "center" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "outline-primary",
                            active: _vm.$route.name === "TeamReports",
                            to:
                              "/team/" +
                              _vm.$route.params.team_id +
                              "/reports/" +
                              _vm.mapId
                          }
                        },
                        [_vm._v(" Reports ")]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "outline-primary",
                            active: _vm.$route.name === "TeamStats",
                            to:
                              "/team/" +
                              _vm.$route.params.team_id +
                              "/stats/" +
                              _vm.mapId
                          }
                        },
                        [_vm._v(" Stats ")]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "outline-primary",
                            active: _vm.$route.name === "TeamMatches",
                            to:
                              "/team/" +
                              _vm.$route.params.team_id +
                              "/matches/" +
                              _vm.mapId
                          }
                        },
                        [_vm._v(" Matches ")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("router-view", { attrs: { name: "team" } })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }